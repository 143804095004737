module.exports = [
  { fromPath: '/welcome-raw-dog-food', toPath: '/', isPermanent: true },
  { fromPath: '/raw-feeding-2015', toPath: '/', isPermanent: true },
  { fromPath: '/dehydrator', toPath: '/', isPermanent: true },
  { fromPath: '/natural-dog-conference-2015', toPath: '/', isPermanent: true },
  { fromPath: '/episode1', toPath: '/talking-nutrition-with-rodney-habib', isPermanent: true },
  { fromPath: '/episode2', toPath: '/talking-toxicity-with-dr-karen-becker', isPermanent: true },
  { fromPath: '/episode3', toPath: '/talking-puppies-vaccinations-intolerances-with-dr-jean-dodds', isPermanent: true },
  { fromPath: '/2015-year-in-review', toPath: '/', isPermanent: true },
  { fromPath: '/episode4', toPath: '/talking-holism-homeopathy-with-dr-nick-thompson', isPermanent: true }
]