import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import config from '../../../content/meta/config'

function SEO({ description, lang, meta, keywords, title }) {
  // const postTitle = ((data || {}).frontmatter || {}).postTitle || ((data || {}).frontmatter || {}).title;
  // const postDescription = ((data || {}).frontmatter || {}).description;
  // const postCover = ((data || {}).frontmatter || {}).cover;
  // const postSlug = ((data || {}).fields || {}).slug;

  // const title = postTitle ? `${postTitle} - ${config.shortSiteTitle}` : config.siteTitle;
  // const description = postDescription ? postDescription : config.siteDescription;
  // const image = postCover ? postCover : config.siteImage;
  // const url = config.siteUrl + config.pathPrefix + postSlug;

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang: config.siteLanguage,
              prefix: 'og: http://ogp.me/ns#',
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              // {
              //   property: `og:url`,
              //   content: config.siteUrl + config.pathPrefix + postSlug,
              // },
              // {
              //   property: `og:image`,
              //   content: postCover ? postCover : config.siteImage,
              // },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                property: `apple-mobile-web-app-capable`,
                content: `yes`,
              },
              {
                property: `apple-mobile-web-app-status-bar-style`,
                content: `#34495E`,
              },
              {
                property: `apple-mobile-web-app-title`,
                content: title,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
            link={[
              {
                rel: 'apple-touch-icon',
                sizes: '57x57',
                href: `/icons/apple-icon-57x57.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '60x60',
                href: `/icons/apple-icon-60x60.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '72x72',
                href: `/icons/apple-icon-72x72.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '76x76',
                href: `/icons/apple-icon-76x76.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '114x114',
                href: `/icons/apple-icon-114x114.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '120x120',
                href: `/icons/apple-icon-120x120.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '144x144',
                href: `/icons/apple-icon-144x144.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '152x152',
                href: `/icons/apple-icon-152x152.png`,
              },
              {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: `/icons/apple-icon-180x180.png`,
              },
              {
                rel: 'shortcut icon',
                href: `/icons/favicon-96x96.png`,
              },
            ]}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string, //.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
